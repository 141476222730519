import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { HEADER_LEVEL } from "../../utils/commonConst";
import HeaderText from "../common/HeaderText";
import PricingCard from "../common/PricingCard";

import { SanaPricingTierData } from "../../data/homeData";
import { Breakpoints } from "../../styles/styleConstants";
import EmployerGetInTouchLink from "../common/EmployerGetInTouchLink";
import SemiBoldText from "../common/SemiBoldText";

export default function SanaPricing(props) {
  const { signupCode } = props ?? {};
  return (
    <>
      <Container>
        <SanaPricingWrapper>
          <HeaderWrapper>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_1}
              style={{ margin: "0px 12px 12px 0px" }}
            >
              Sana employees get VIP access
            </HeaderText>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_4}
              style={{ margin: "12px 0px" }}
            >
              Use code SANA for your <SemiBoldText>$1</SemiBoldText> money
              allocation plan.
            </HeaderText>
          </HeaderWrapper>
          <PricingCardWrapper>
            {SanaPricingTierData.map((sanaPricingTier, indx) => {
              return (
                <PricingCard
                  signupCode={signupCode}
                  key={`${indx}-ptier-${new Date().getSeconds()}`}
                  pricingTierData={sanaPricingTier}
                  className="sana-pricing-card"
                />
              );
            })}
          </PricingCardWrapper>
          {/* <ContactUsWrapper>
            <EmployerGetInTouchLink />
          </ContactUsWrapper> */}
        </SanaPricingWrapper>
      </Container>
    </>
  );
}

const SanaPricingWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: block;
  margin: 96px 0px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  text-align: center;
`;

const PricingCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 40px;
  @media (max-width: ${Breakpoints.sm}) {
    gap: 12px;
    padding: 0px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
  }

  .sana-pricing-card {
    width: 448px;
    height: 496px;
    @media (max-width: ${Breakpoints.sm}) {
      min-width: 275px;
      height: 550px;
    }
  }
`;

const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
